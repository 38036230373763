import React from 'react'
import { Helmet } from 'react-helmet'
import { Link as GatsbyLink, graphql } from 'gatsby'
import get from 'lodash/get'
import rehypeReact from 'rehype-react'

import Layout from '../components/layout'
import ZoomImg from '../components/zoomImg'
import Table from '../components/table'
import Link from '../components/link'
import { getEventDate } from '../utils'

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    img: ZoomImg,
    table: Table,
    a: Link,
  },
}).Compiler

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const siteDescription = post.excerpt
    const { previous, next } = this.props.pageContext
    const { title, date, eventDate, eventDateEnd, tags } = post.frontmatter
    const formattedEventDates = getEventDate(eventDate, eventDateEnd)

    return (
      <Layout location={this.props.location} customClass="blog-post">
        <Helmet
          htmlAttributes={{ lang: 'cs' }}
          title={`${title} | ${siteTitle}`}
        >
          <meta name="description" content={siteDescription} />
        </Helmet>
        <article className="article-detail">
          <header>
            <h1>{title}</h1>
            {tags ? (
              <p className="article-detail-tags">
                {tags.map((tag) => (
                  <GatsbyLink
                    key={tag}
                    to={`/aktuality#${tag}`}
                    className="article-tag"
                  >
                    {tag}
                  </GatsbyLink>
                ))}
              </p>
            ) : null}
            <p className="article-detail-date">
              {formattedEventDates != null && (
                <span>
                  <i>datum konání: </i>
                  <b>{formattedEventDates}</b>
                  {', '}
                </span>
              )}
              <i>publikováno: </i>
              <b>{date}</b>
            </p>
          </header>
          <div className="article-detail-content">
            {renderAst(post.htmlAst)}
          </div>
          <hr />
          <ul className="article-navigation">
            {previous && (
              <li className="article-link-prev">
                <GatsbyLink
                  to={previous.fields.slug}
                  rel="prev"
                  title="Předchozí článek"
                >
                  ← {previous.frontmatter.title}
                </GatsbyLink>
              </li>
            )}

            {next && (
              <li className="article-link-next">
                <GatsbyLink
                  to={next.fields.slug}
                  rel="next"
                  title="Další článek"
                >
                  {next.frontmatter.title} →
                </GatsbyLink>
              </li>
            )}
          </ul>
        </article>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      htmlAst
      frontmatter {
        title
        date(formatString: "D. M. YYYY")
        eventDate(formatString: "D. M. YYYY")
        eventDateEnd(formatString: "D. M. YYYY")
        tags
      }
    }
  }
`
